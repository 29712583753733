import { useDebounce } from './hooks/useDebounce';
import { useWindowSize } from './hooks/useWindowSize';
import { ThemeDefault } from '@paygreen/paygreen-ui';

const getNumberFromMediaQuery = themeQuery => {
    return Number(themeQuery.replace(/[^.\d]/g, ''));
};

/**
 * @description to detect dynamically screen size above 960px width
 */
const IsDesktop = () => {
    const windowSize = useDebounce(useWindowSize(), 200);

    return (
        windowSize.width >= getNumberFromMediaQuery(ThemeDefault.query.min.md)
    );
};

/**
 * @description to detect dynamically screen size above 1200px width
 */
const IsBigDesktop = () => {
    const windowSize = useDebounce(useWindowSize(), 200);

    return (
        windowSize.width >= getNumberFromMediaQuery(ThemeDefault.query.min.lg)
    );
};

/**
 * @description to detect dynamically screen size under 549px width
 */
const IsMobile = () => {
    const windowSize = useDebounce(useWindowSize(), 200);

    return (
        windowSize.width <= getNumberFromMediaQuery(ThemeDefault.query.max.sm)
    );
};

/**
 * Method from 'https://www.w3resource.com/javascript-exercises/javascript-string-exercise-16.php'
 */
const Truncate = (str, length = 100, ending = '...') => {
    if (str === null) {
        return '';
    }
    if (str.length > length) {
        return str.substring(0, length - ending.length) + ending;
    } else {
        return str;
    }
};

export { getNumberFromMediaQuery, IsBigDesktop, IsDesktop, IsMobile, Truncate };
