import React from 'react';
import { Title } from '@paygreen/paygreen-ui';

/**
 * @description this component is based on Title component from PG-UI and will be rendered as `<h3>`
 */
const H2Title = ({ children, ...rest }) => {
    return (
        <Title
            htmlTag="h3"
            textSize="xs"
            colorWab="grey40"
            marginBottom="sm"
            marginTop="sm"
            {...rest}
        >
            {children}
        </Title>
    );
};

export default H2Title;
