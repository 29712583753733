import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { LocalizedLink } from 'gatsby-theme-i18n';
import { Link } from 'gatsby';
import packageJson from '../../../package.json';
import {
    Grid,
    InternalGrid,
    ListHorizontal,
    Link as PgLink,
    Footer as PGFooter,
    FooterList,
    Text,
    Title,
    ThemeDefault,
} from '@paygreen/paygreen-ui';
import FbIcon from '../../../assets/social-icons/Fb';
import TwIcon from '../../../assets/social-icons/Tw';
import InIcon from '../../../assets/social-icons/In';

const titleStyles = {
    align: 'center',
    textSize: 'xs',
    colorTheme: 'secondary',
    colorWab: 'grey30',
    hasUnderline: true,
    marginBottom: 'xs',
};

const linkStyles = {
    hasUnderline: false,
    colorPallet: 'wab',
    colorWab: 'grey50',
};

const listStyles = {
    align: 'center',
    textSize: 'xs',
    marginBottom: 'md',
};

const SocialLink = styled.a`
    display: block;

    span {
        width: ${ThemeDefault.space.lg};
        fill: ${ThemeDefault.color.primary.main};
    }
`;

const linksPayment = [
    {
        label: 'API',
        url: '/payment/api',
    },
    {
        label: 'Modules',
        url: '/payment/modules',
    },
    {
        label: 'SDK JS',
        url: '/payment/sdk-js',
    },
    {
        label: 'SDK PHP',
        url: '/payment/sdk-php',
    },
];

const linksClimateKit = [
    {
        label: 'API',
        url: '/climateKit/api',
    },
    {
        label: 'SDK JS',
        url: '/climateKit/sdk-js',
    },
];

const linksCharityKit = [
    {
        label: 'API',
        url: '/charityKit/api',
    },
    {
        label: 'Modules',
        url: '/charityKit/modules',
    },
];

const Footer = () => {
    const { t } = useTranslation('pages');

    return (
        <PGFooter topStyle="right">
            <Grid childrenFlex={1}>
                <div>
                    <Title {...titleStyles}>{t('menu.paymentLink')}</Title>

                    <FooterList {...listStyles}>
                        {linksPayment.map((link, index) => (
                            <LocalizedLink to={link.url} key={index}>
                                <PgLink {...linkStyles}>{link.label}</PgLink>
                            </LocalizedLink>
                        ))}
                    </FooterList>
                </div>

                <div>
                    <Title {...titleStyles}>{t('menu.charityKitLink')}</Title>

                    <FooterList {...listStyles}>
                        {linksCharityKit.map((link, index) => (
                            <LocalizedLink to={link.url} key={index}>
                                <PgLink {...linkStyles}>{link.label}</PgLink>
                            </LocalizedLink>
                        ))}
                    </FooterList>
                </div>

                <div>
                    <Title {...titleStyles}>{t('menu.climateKitLink')}</Title>

                    <FooterList {...listStyles}>
                        {linksClimateKit.map((link, index) => (
                            <LocalizedLink to={link.url} key={index}>
                                <PgLink {...linkStyles}>{link.label}</PgLink>
                            </LocalizedLink>
                        ))}
                    </FooterList>
                </div>

                <div className="hideOnTablet">
                    <Title {...titleStyles}>{t('footer.followLink')}</Title>

                    <InternalGrid
                        justifyContent="center"
                        childrenMarginLateral="sm"
                    >
                        <SocialLink
                            href="https://www.facebook.com/PayGreen.fr/"
                            target="_blank"
                        >
                            <FbIcon />
                        </SocialLink>

                        <SocialLink
                            href="https://twitter.com/paygreen_fr?lang=fr"
                            target="_blank"
                        >
                            <TwIcon />
                        </SocialLink>

                        <SocialLink
                            href="https://fr.linkedin.com/company/paygreen"
                            target="_blank"
                        >
                            <InIcon />
                        </SocialLink>
                    </InternalGrid>
                </div>
            </Grid>

            <Text
                align="center"
                textSize="xxs"
                colorWab="grey40"
                hasUppercase={true}
            >
                © 2020 – PayGreen
            </Text>

            <ListHorizontal align="center" textSize="xxs" colorWab="grey50">
                <Link to="/">
                    <PgLink
                        hasUnderline={false}
                        colorPallet="wab"
                        colorWab="grey60"
                    >
                        {t('footer.legalMentions')}
                    </PgLink>
                </Link>

                <Link to="/">
                    <PgLink
                        hasUnderline={false}
                        colorPallet="wab"
                        colorWab="grey60"
                    >
                        {t('footer.termsService')}
                    </PgLink>
                </Link>
            </ListHorizontal>
            <Text
                align="center"
                textSize="xxs"
                colorWab="grey40"
                marginBottom="xs"
            >
                V{packageJson.version}
            </Text>
        </PGFooter>
    );
};

Footer.propTypes = {
    siteTitle: PropTypes.string,
};

Footer.defaultProps = {
    siteTitle: '',
};

export default Footer;
