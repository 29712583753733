import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useTranslation } from 'react-i18next';
import {
    Box,
    DaInput,
    MenuItem,
    ModalGroup,
    Modal,
    ModalBody,
    ModalControl,
    Overlay,
    DaButton,
    ButtonGroup,
    ModalContent,
} from '@paygreen/paygreen-ui';
import SearchResults from './SearchResults';
import { IsBigDesktop } from '../../utils/Tools';

const SearchBlock = () => {
    const { t } = useTranslation('pages');
    const [data, setData] = useState(null);

    // we query data from index built by elasticlunr-search plugin
    const dataResults = useStaticQuery(graphql`
        query SearchIndexQuery {
            siteSearchIndex {
                index
            }
        }
    `);

    useEffect(() => {
        setData(dataResults);
    }, [dataResults, data]);

    return (
        <>
            {IsBigDesktop() ? (
                <ModalGroup>
                    <ModalControl>
                        <button type="button">
                            <DaInput
                                onClick={() => setData(null)}
                                placeholder={t('searchPlaceholder')}
                                blockWidth="sm"
                                fieldSize="sm"
                            />
                        </button>
                    </ModalControl>

                    <Modal>
                        <ModalControl>
                            <Overlay opacityValue={20} />
                        </ModalControl>

                        <ModalContent>
                            <ModalBody
                                paddingLateral="sm"
                                paddingTop="sm"
                                paddingBottom="sm"
                            >
                                {data && (
                                    <SearchResults
                                        searchIndex={data.siteSearchIndex.index}
                                    />
                                )}

                                <ButtonGroup marginTop="xs">
                                    <ModalControl>
                                        <button type="button">
                                            <DaButton
                                                buttonSize="md"
                                                colorPallet="status"
                                                colorStatus={'danger'}
                                            >
                                                {t('cancelButton')}
                                            </DaButton>
                                        </button>
                                    </ModalControl>
                                </ButtonGroup>
                            </ModalBody>
                        </ModalContent>
                    </Modal>
                </ModalGroup>
            ) : (
                data && (
                    <>
                        <MenuItem isClickable={false}>
                            {t('menu.searchLink')}
                        </MenuItem>

                        <Box marginTop="md" marginLeft="sm" marginRight="sm">
                            <SearchResults
                                searchIndex={data.siteSearchIndex.index}
                            />
                        </Box>
                    </>
                )
            )}
        </>
    );
};

export default SearchBlock;
