import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { LocalizedLink, useLocalization } from 'gatsby-theme-i18n';
import {
    Box,
    Link,
    Menu,
    MenuItem,
    MenuList,
    MenuListItem,
    MenuSecondary as PGMenuSecondary,
} from '@paygreen/paygreen-ui';
import { SearchBlock } from '../../../components';
import { subLinkStyle } from './index';

const MenuSecondary = ({ colorTheme, closeSecondaryMenu, ...rest }) => {
    const { t } = useTranslation('pages');
    const { locale, defaultLang } = useLocalization();
    const [pathname, setPathname] = useState();

    const toDefaultLangPath = currentPath => {
        if (locale !== defaultLang) {
            return currentPath.replace('/' + locale, '');
        } else {
            return currentPath;
        }
    };

    // to avoid error during build with server side rendering, so the code doesn’t run unless it’s mounted and in the browser.
    useEffect(() => {
        setPathname(window.location.pathname);
    }, []);

    return (
        <PGMenuSecondary {...rest}>
            <Menu htmlTag="div">
                <MenuItem isClickable={false}>
                    {t('menu.languageLink')}
                </MenuItem>

                <MenuList colorTheme={colorTheme}>
                    {pathname && (
                        <LocalizedLink
                            to={toDefaultLangPath(pathname)}
                            language="fr"
                        >
                            <MenuListItem colorTheme="secondary">
                                <Box marginRight="sm">
                                    <span role="img" aria-label="french flag">
                                        🇫🇷
                                    </span>
                                </Box>

                                <Link
                                    {...subLinkStyle.link}
                                    colorTheme="secondary"
                                >
                                    Français
                                </Link>
                            </MenuListItem>
                        </LocalizedLink>
                    )}

                    {pathname && (
                        <LocalizedLink to={pathname} language="en">
                            <MenuListItem colorTheme="secondary">
                                <Box marginRight="sm">
                                    <span role="img" aria-label="english flag">
                                        🇬🇧
                                    </span>
                                </Box>

                                <Link
                                    {...subLinkStyle.link}
                                    colorTheme="secondary"
                                >
                                    English
                                </Link>
                            </MenuListItem>
                        </LocalizedLink>
                    )}
                </MenuList>
            </Menu>

            <SearchBlock />
        </PGMenuSecondary>
    );
};

MenuSecondary.propTypes = {
    closeComponent: PropTypes.element,
    isOpen: PropTypes.bool,
    colorTheme: PropTypes.oneOf([
        'primary',
        'secondary',
        'tertiary',
        'quaternary',
        'quinary',
    ]),
};

MenuSecondary.defaultProps = {
    isOpen: false,
    colorTheme: 'primary',
};

export default MenuSecondary;
