import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Grid,
    Text,
    ButtonGroup,
    DaButton,
    Divider,
} from '@paygreen/paygreen-ui';
import '../../../assets/css/gatsbyHighlights.css';

const ContactDivider = () => {
    const { t } = useTranslation('pages');

    return (
        <>
            <Divider
                waveStyle="right"
                colorTheme="secondary"
                marginBottom="none"
            />

            <Grid
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                childrenMarginBig="xs"
            >
                <Text align="center">{t('homepage.questions')}</Text>

                <ButtonGroup>
                    <a href="mailto:tech@paygreen.fr" rel="noopener noreferrer">
                        <DaButton gradient="brand" buttonSize="lg">
                            {t('homepage.contact')}
                        </DaButton>
                    </a>
                </ButtonGroup>
            </Grid>
        </>
    );
};

export default ContactDivider;
