import MenuPrimary from './MenuPrimary';
import MenuSecondary from './MenuSecondary';
import TopbarMobile from './TopbarMobile';
import MenuContextItem from './MenuContextItem/MenuContextItem';
import MenuContext from './MenuContext/MenuContext';

const subLinkStyle = {
    link: {
        hasHover: false,
    },
    icon: {
        iconSize: 'lg',
    },
};

const subLinkGreyStyle = {
    link: {
        ...subLinkStyle.link,
        colorPallet: 'wab',
        colorWab: 'grey60',
    },
    icon: {
        ...subLinkStyle.icon,
        iconSize: 'lg',
        colorPallet: 'wab',
        colorWab: 'grey50',
    },
};

export {
    MenuPrimary,
    MenuSecondary,
    MenuContextItem,
    MenuContext,
    TopbarMobile,
    subLinkStyle,
    subLinkGreyStyle,
};
