import React from 'react';
import { Text } from '@paygreen/paygreen-ui';

/**
 * @description this component is based on Text component from PG-UI and will be rendered as `<p>`
 */
const PText = ({ children, ...rest }) => {
    return (
        <Text
            htmlTag="p"
            textSize="sm"
            marginTop="xs"
            marginBottom="xs"
            {...rest}
        >
            {children}
        </Text>
    );
};

export default PText;
