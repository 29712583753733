import React from 'react';
import PropTypes from 'prop-types';
import { Dot } from '@paygreen/paygreen-ui';
import { MenuContextItemBase } from './style';

const MenuContextItem = props => {
    return (
        <MenuContextItemBase
            {...props}
            onClick={props.toggleMenu ? () => props.toggleMenu() : null}
        >
            {props.hasDot && (
                <Dot
                    dotSize="xxs"
                    marginRight="sm"
                    marginLeft="md"
                    colorTheme={props.activeColor}
                />
            )}

            {React.Children.map(props.children, (child, index) => {
                if (!child) {
                    return null;
                }

                if (child.type === 'a')
                    return React.cloneElement(child, {
                        key: index,
                        className:
                            (child.props && child.props.href) ===
                            '#' + props.activeLink
                                ? 'active'
                                : null,
                    });

                return child;
            })}
        </MenuContextItemBase>
    );
};

MenuContextItem.propTypes = {
    activeLink: PropTypes.string,
    toggleMenu: PropTypes.func,
    activeColor: PropTypes.string,
    textSize: PropTypes.string,
    hasDot: PropTypes.bool,
};

MenuContextItem.defaultProps = {
    activeLink: '',
    activeColor: 'primary',
    textSize: 'base',
    hasDot: false,
};

export default MenuContextItem;
