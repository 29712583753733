import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Dot,
    Header as PGHeader,
    MenuGroup,
    MenuClose,
} from '@paygreen/paygreen-ui';
import { MenuPrimary, MenuSecondary, TopbarMobile } from './menus';

const Header = props => {
    const [primaryMenuOpened, setPrimaryMenuOpened] = useState(false);
    const [secondaryMenuOpened, setSecondaryMenuOpened] = useState(false);

    const togglePrimaryMenu = () => {
        setPrimaryMenuOpened(!primaryMenuOpened);
        if (secondaryMenuOpened) {
            setSecondaryMenuOpened(false);
        }
    };

    const toggleSecondaryMenu = () => {
        setSecondaryMenuOpened(!secondaryMenuOpened);
        if (primaryMenuOpened) {
            setPrimaryMenuOpened(false);
        }
    };

    const closeSecondaryMenu = () => {
        setSecondaryMenuOpened(false);
    };

    useEffect(() => {}, [primaryMenuOpened]);

    return (
        <PGHeader hasTopStyle={props.hasTopStyle}>
            <MenuGroup>
                <TopbarMobile
                    primaryMenuOpened={primaryMenuOpened}
                    togglePrimaryMenu={togglePrimaryMenu}
                    toggleSecondaryMenu={toggleSecondaryMenu}
                />

                <MenuPrimary
                    isOpen={primaryMenuOpened}
                    toggleMenu={togglePrimaryMenu}
                    colorTheme={props.colorTheme}
                />

                <Dot
                    className="hideOnSmallScreen"
                    colorPallet="wab"
                    colorWab="grey10"
                    marginLeft="lg"
                    marginRight="lg"
                />

                <MenuSecondary
                    closeSecondaryMenu={closeSecondaryMenu}
                    isOpen={secondaryMenuOpened}
                    colorTheme={props.colorTheme}
                    closeComponent={
                        <MenuClose
                            onClick={() => setSecondaryMenuOpened(false)}
                            colorTheme={props.colorTheme}
                        />
                    }
                />
            </MenuGroup>
        </PGHeader>
    );
};

Header.propTypes = {
    colorTheme: PropTypes.oneOf([
        'primary',
        'secondary',
        'tertiary',
        'quaternary',
        'quinary',
    ]),
};

Header.defaultProps = {
    colorTheme: 'primary',
};

export default Header;
