import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { transparentize } from 'polished';
import { LocalizedLink } from 'gatsby-theme-i18n';
import {
    Card,
    Grid,
    Image,
    InternalGrid,
    Text,
    Title,
} from '@paygreen/paygreen-ui';
import { IsBigDesktop } from '../../utils/Tools';

const ClickableContainer = styled.div`
        a:hover, a:active, a:focus {
            .internal-card {
                    border-radius: ${props => props.theme.radius.lg};
                    background-color: ${props => props.theme.wab.white00};
                    box-shadow: ${props =>
                        props.theme.shadow.size.sm +
                        ' ' +
                        transparentize(
                            props.theme.shadow.opacity.sm - 0.1,
                            props.theme.color.primary.main,
                        )};
                }
            }
        }
`;

const ToolsCard = ({ icon, link, title, text }) => {
    return (
        <ClickableContainer>
            <LocalizedLink to={link}>
                <Card
                    className="internal-card"
                    paddingLateral="xs"
                    paddingBottom="xs"
                    paddingTop="xs"
                    hasBackground={false}
                >
                    <Grid
                        flexDirection={IsBigDesktop() ? 'row' : 'column'}
                        alignItems="center"
                        justifyContent={
                            IsBigDesktop() ? 'flex-start' : 'center'
                        }
                        childrenMarginBig="sm"
                        childrenMargin="sm"
                        flexWrap="nowrap"
                        blockPadding="none"
                    >
                        <Image
                            isCircle={true}
                            blockWidth="xxs"
                            marginRight="md"
                        >
                            <img src={icon} alt={title} />
                        </Image>

                        <InternalGrid
                            displayType="grid"
                            gridTemplateColumns="1fr"
                            alignItems="center"
                            justifyItems={IsBigDesktop() ? 'start' : 'center'}
                        >
                            <Title
                                colorWab="grey40"
                                align={IsBigDesktop() ? 'left' : 'center'}
                            >
                                {title}
                            </Title>

                            {text ? (
                                <Text
                                    className="hideOnMobile"
                                    textSize="sm"
                                    align="left"
                                >
                                    {text}
                                </Text>
                            ) : null}
                        </InternalGrid>
                    </Grid>
                </Card>
            </LocalizedLink>
        </ClickableContainer>
    );
};

ToolsCard.propTypes = {
    link: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
};

ToolsCard.defaultProps = {
    text: '',
};

export default ToolsCard;
