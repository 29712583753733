import React from 'react';
import PropTypes from 'prop-types';
import { Link as LinkGatsby } from 'gatsby';
import { MenuHamburger, WheelsIcon } from '@paygreen/paygreen-ui';
import LogoPaygreen from '../../../../assets/logos/LogoPayGreen';

const TopbarMobile = props => {
    return (
        <div className="main-nav">
            <MenuHamburger
                onClick={props.togglePrimaryMenu}
                isOpen={props.primaryMenuOpened}
            />

            <LinkGatsby to="/">
                <LogoPaygreen />
            </LinkGatsby>

            <WheelsIcon
                onClick={props.toggleSecondaryMenu}
                htmlTag="button"
                iconSize="xl"
                colorTheme="secondary"
            />
        </div>
    );
};

TopbarMobile.propTypes = {
    primaryMenuOpened: PropTypes.bool,
    togglePrimaryMenu: PropTypes.func.isRequired,
    toggleSecondaryMenu: PropTypes.func.isRequired,
};

TopbarMobile.defaultProps = {
    primaryMenuOpened: false,
};

export default TopbarMobile;
