import styled from 'styled-components';

const MenuContextItemBase = styled.li`
    display: ${props => (props.hasDot ? 'flex' : 'block')};
    align-items: center;
    font-size: ${props => props.theme.font.size[props.textSize]};
    line-height: ${props => props.theme.font.lineHeight.md};
    margin-top: ${props => props.theme.space.sm};
    margin-bottom: ${props => props.theme.space.sm};
    margin-left: ${props => props.theme.space.md};

    .active {
        span {
            color: ${props => props.theme.color[props.activeColor].main};
            &::after {
                background-color: ${props =>
                    props.theme.color[props.activeColor].main};
            }
        }
    }
`;

export { MenuContextItemBase };
