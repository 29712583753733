import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { Link, List, ListItem, Title, Text } from '@paygreen/paygreen-ui';
import { Truncate } from '../../utils/Tools';

const FeaturesList = ({ data, colorTheme }) => {
    const { t } = useTranslation('pages');

    return (
        <List bulletSize="sm" marginTop="xs" marginBottom="xs">
            {data.featuresList.map(({ title, description, link }, index) => (
                <ListItem
                    colorTheme={colorTheme}
                    key={index}
                    bulletSize="sm"
                    marginBottom="md"
                >
                    {link && title && data.id ? (
                        <Title htmlTag="h3" textSize="md">
                            <GatsbyLink to={link}>
                                <Link
                                    colorTheme={colorTheme}
                                    hasUnderline={false}
                                >
                                    {t(
                                        `homepage.sections.${data.id}.featuresList.${title}`,
                                    )}
                                </Link>
                            </GatsbyLink>
                        </Title>
                    ) : null}

                    {description ? (
                        <Text textSize="sm" marginTop="xs">
                            {Truncate(description)}
                        </Text>
                    ) : null}
                </ListItem>
            ))}
        </List>
    );
};

FeaturesList.propTypes = {
    data: PropTypes.object.isRequired,
    colorTheme: PropTypes.string,
};

FeaturesList.defaultProps = {
    colorTheme: 'primary',
};

export default FeaturesList;
