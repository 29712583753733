import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

const MenuContextBase = styled.div`
    z-index: ${props => props.theme.zindex.menu};
    box-sizing: border-box;

    .menu {
        padding: 0;
        list-style-type: none;
        max-height: 60vh;
        min-width: ${props => props.theme.blockWidth.xs};
        overflow-y: auto;
    }

    .menu-close {
        margin: 0 auto ${props => props.theme.space.md} auto;
    }

    @media ${props => props.theme.screen.min.lg} {
        position: sticky;
        top: ${props => props.theme.blockShift.sm};
        margin-left: ${props => props.theme.blockHeader.none};
        padding: ${props => props.theme.space.md};
        border-radius: ${props => props.theme.radius.lg};
        background-color: ${props => props.theme.wab.white00};
        box-shadow: ${props =>
            props.theme.shadow.size.sm +
            ' ' +
            transparentize(
                props.theme.shadow.opacity.md - 0.1,
                props.theme.color.primary.main,
            )};
    }

    @media ${props => props.theme.screen.max.lg} {
        margin: 0;
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        max-height: 80vh;
        background-color: ${props => props.theme.wab.white00};
        transition: all ${props => props.theme.transition.sm};
        box-shadow: 0 0 20px
            ${props =>
                transparentize(
                    props.theme.shadow.opacity.lg,
                    props.theme.wab.black00,
                )};

        ${props => (props.isOpen ? null : closedStyle)};
    }
`;

const closedStyle = css`
    transform: translateY(100%);
    box-shadow: unset;
`;

export { MenuContextBase };
