import React from 'react';
import PropTypes from 'prop-types';
import { AutoBlockScroll, MenuClose, Overlay } from '@paygreen/paygreen-ui';
import { MenuContextBase } from './style';

const MenuContext = ({ toggleMenu, children, isOpen }) => {
    return (
        <>
            {isOpen ? (
                <>
                    <AutoBlockScroll hasScrollOnDesktop={true} />
                    <Overlay
                        className="hideOnBigScreen"
                        onClick={toggleMenu}
                        opacityValue={20}
                    />
                </>
            ) : null}

            <MenuContextBase isOpen={isOpen}>
                <MenuClose
                    className="menu-close hideOnBigScreen"
                    isOpen={isOpen}
                    onClick={toggleMenu}
                    position="top"
                />

                <ul className="menu">{children}</ul>
            </MenuContextBase>
        </>
    );
};

MenuContext.propTypes = {
    isOpen: PropTypes.bool,
    toggleMenu: PropTypes.func.isRequired,
};

MenuContext.defaultProps = {
    isOpen: false,
};

export default MenuContext;
