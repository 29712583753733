import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Index } from 'elasticlunr';
import { useLocalization } from 'gatsby-theme-i18n';
import { useTranslation } from 'react-i18next';
import { DaInput, Text, ThemeDefault } from '@paygreen/paygreen-ui';
import SearchItem from './SearchItem';

const ResultsWrapper = styled.div`
    @media (${ThemeDefault.query.max.lg}) {
        max-height: 200px;
        overflow-y: auto;
    }

    ul {
        padding: 0;
        list-style-type: none;
    }
`;

const SearchResults = ({ searchIndex }) => {
    const { t } = useTranslation('pages');
    const index = Index.load(searchIndex);
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const { locale } = useLocalization();

    // we launch search and autofocus on the input when user has clicked on header search element
    useEffect(() => {
        searchResults();
        document.getElementById('Search').focus();
    }, []); // eslint-disable-line

    // we map search data results from index built by elasticlunr-search plugin and filter them by language
    const searchResults = searchQuery => {
        const res = index
            .search(searchQuery, { expand: true })
            .map(({ ref }) => {
                return index.documentStore.getDoc(ref);
            });
        setResults(res.filter(doc => doc.language === locale));
    };

    return (
        <>
            <DaInput
                label="Search"
                onChange={e => {
                    setQuery(e.target.value);
                    searchResults(e.target.value);
                }}
                placeholder={t('searchPlaceholder')}
                value={query}
                id="Search"
                blockWidth="lg"
                fieldSize="lg"
            />

            {results.length > 0 ? (
                <ResultsWrapper>
                    <ul>
                        {results.map(page => (
                            <SearchItem
                                key={page.id}
                                path={`${page.path}`}
                                query={query}
                                title={page.title}
                            />
                        ))}
                    </ul>
                </ResultsWrapper>
            ) : (
                <Text marginTop="sm" colorWab="grey30" align="center">
                    {t('searchResults')}
                </Text>
            )}
        </>
    );
};

export default SearchResults;
