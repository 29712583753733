import React from 'react';
import { LocalizedLink } from 'gatsby-theme-i18n';
import PropTypes from 'prop-types';
import {
    Card,
    Title,
    ButtonGroup,
    DaButton,
    Text,
    InternalGrid,
} from '@paygreen/paygreen-ui';

export const InternalLinkCardGrid = props => {
    return (
        <InternalGrid
            flewWrap="wrap"
            justifyContent="center"
            childrenMarginTop="md"
            childrenMarginBottom="md"
            childrenMarginLateral="lg"
            alignItems="center"
        >
            {props.children}
        </InternalGrid>
    );
};

const InternalLinkCard = ({colorTheme, link, resume, title}) => {
    return (
        <Card borderTop="theme" colorTheme={colorTheme} blockWidth="xs">
            {title && (
                <Title
                    textSize="md"
                    marginTop="sm"
                    marginBottom="xs"
                    marginLateral="sm"
                    colorWab="grey50"
                >
                    {title}
                </Title>
            )}

            {resume && (
                <Text
                    htmlTag="div"
                    marginTop="xs"
                    marginBottom="sm"
                    marginLateral="sm"
                    textSize="sm"
                >
                    {resume}
                </Text>
            )}

            {link && (
                <ButtonGroup marginTop="sm" marginBottom="md">
                    <LocalizedLink to={link}>
                        <DaButton
                            gradient="theme"
                            buttonSize="md"
                            colorTheme={colorTheme}
                        >
                            consulter
                        </DaButton>
                    </LocalizedLink>
                </ButtonGroup>
            )}
        </Card>
    );
};

InternalLinkCard.propTypes = {
    link: PropTypes.string,
    title: PropTypes.string,
    resume: PropTypes.string,
    colorTheme: PropTypes.string,
};

InternalLinkCard.defaultProps = {
    colorTheme: 'primary',
};

export default InternalLinkCard;
