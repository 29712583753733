import React from 'react';
import { Logo } from "@paygreen/paygreen-ui";

const InIcon = () => (
    <Logo hasHoverColor={true} blockWidth="xs">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 488">
            <path d="M244,0C109.2,0,0,109.2,0,244s109.2,244,244,244s244-109.2,244-244S378.8,0,244,0z M168.6,377.9h-59.4V199.2
                h59.4V377.9z M138.9,174.9h-0.4c-19.9,0-32.8-13.7-32.8-30.9c0-17.5,13.3-30.9,33.6-30.9s32.8,13.3,33.2,30.9
                C172.5,161.1,159.6,174.9,138.9,174.9L138.9,174.9z M382.7,377.9h-59.4v-95.6c0-24-8.6-40.4-30.1-40.4c-16.4,0-26.2,11-30.5,21.7
                c-1.6,3.8-2,9.1-2,14.5v99.8h-59.4c0,0,0.8-161.9,0-178.6h59.4v25.3c7.9-12.2,22-29.5,53.5-29.5c39.1,0,68.4,25.5,68.4,80.4v102.4
                H382.7z"/>
        </svg>
    </Logo>
);

export default InIcon;