import React from 'react';
import { Card, Text, Title } from '@paygreen/paygreen-ui';

const MessageCard = props => {
    return (
        <Card
            colorPallet="theme"
            colorTheme={props.colorTheme}
            shadowSize="none"
            blockWidth="lg"
        >
            <Title
                marginTop="sm"
                marginLateral="sm"
                colorPallet="theme"
                colorTheme={props.colorTheme}
            >
                {props.title}
            </Title>

            <Text
                textSize="sm"
                marginTop="xs"
                marginLateral="sm"
                marginBottom="sm"
            >
                {props.text}
            </Text>
        </Card>
    );
};

export default MessageCard;
