import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Banner,
    Card,
    Grid,
    InternalGrid,
    Title,
    Text,
} from '@paygreen/paygreen-ui';
import FeaturesList from './FeaturesList';
import { IconByName } from '../../components';

const FeaturesSection = ({ data, index }) => {
    const { t } = useTranslation('pages');

    return (
        <>
            <Banner
                colorTheme={data.colorTheme}
                bottomStyle={index % 2 ? 'left' : 'right'}
                topStyle={index % 2 ? 'right' : 'left'}
            >
                <Grid displayType="grid">
                    <Card hasBackground={false} blockWidth="lg">
                        <Title
                            htmlTag="h1"
                            colorType="reverse"
                            textSize="xl"
                            hasUnderline={true}
                            colorTheme={data.colorTheme}
                        >
                            {t(
                                `homepage.sections.${data.id}.${data.bannerTitle}`,
                            )}
                        </Title>

                        <Text
                            colorType="reverse"
                            textSize="md"
                            colorTheme={data.colorTheme}
                        >
                            {data.bannerSubTitle}
                        </Text>
                    </Card>
                </Grid>
            </Banner>

            <Grid
                displayType="grid"
                gridGap="sm"
                isNegativeShift={true}
                childrenShiftSize="md"
            >
                <Card
                    blockWidth="lg"
                    colorTheme={data.colorTheme}
                    hasBackground={false}
                    shadowSize="none"
                >
                    <div>
                        <FeaturesList
                            data={data}
                            colorTheme={data.colorTheme}
                        />
                    </div>
                </Card>

                <Card
                    blockWidth="lg"
                    paddingTop="md"
                    paddingBottom="md"
                    paddingLateral="md"
                    colorTheme={data.colorTheme}
                >
                    <InternalGrid
                        gridTemplateColumns="auto 1fr"
                        displayType="grid"
                        alignItems="center"
                        justifyItems="start"
                    >
                        <IconByName
                            colorTheme={data.colorTheme}
                            iconSize="lg"
                            hasBackground={true}
                            name={data.icon}
                            isActive={true}
                        />

                        <Title
                            htmlTag="h3"
                            textSize="lg"
                            marginLateral="sm"
                            colorPallet="theme"
                            colorTheme={data.colorTheme}
                        >
                            {t(
                                `homepage.sections.${data.id}.${data.cardTitle}`,
                            )}
                        </Title>
                    </InternalGrid>

                    <Text
                        htmlTag="div"
                        textSize="sm"
                        marginTop="sm"
                        marginInternal="sm"
                    >
                        <Text htmlTag="p">{data.cardDescription1}</Text>

                        <Text htmlTag="p">{data.cardDescription2}</Text>
                    </Text>
                </Card>
            </Grid>
        </>
    );
};

FeaturesSection.propTypes = {
    data: PropTypes.object.isRequired,
    index: PropTypes.number,
};

FeaturesSection.defaultProps = {
    index: 0,
};

export default FeaturesSection;
