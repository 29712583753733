import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { LocalizedLink } from 'gatsby-theme-i18n';
import {
    CardsIcon,
    LeafIcon,
    Link,
    MenuPrimary as PGMenuPrimary,
    Menu,
    MenuItem,
    MenuList,
    MenuListItem,
    OrganizationIcon,
    Text,
} from '@paygreen/paygreen-ui';
import { subLinkStyle } from './index';

const MenuPrimary = ({ toggleMenu, colorTheme, ...rest }) => {
    const { t } = useTranslation('pages');

    /**
     * Allow to add onClick to close menu (on mobile)
     */
    const LinkWrapper = ({ children, to, ...rest }) => {
        return (
            <LocalizedLink onClick={toggleMenu} to={to} {...rest}>
                {children}
            </LocalizedLink>
        );
    };

    return (
        <PGMenuPrimary {...rest}>
            <Menu>
                <MenuItem isClickable={false}>{t('menu.paymentLink')}</MenuItem>

                <MenuList colorTheme={colorTheme}>
                    <LinkWrapper to="/payment/api">
                        <MenuListItem colorTheme="secondary">
                            <CardsIcon
                                {...subLinkStyle.icon}
                                colorTheme="secondary"
                            />

                            <Link {...subLinkStyle.link} colorTheme="secondary">
                                API
                            </Link>

                            <Text>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit.
                            </Text>
                        </MenuListItem>
                    </LinkWrapper>

                    <LinkWrapper to="/payment/modules">
                        <MenuListItem colorTheme="secondary">
                            <CardsIcon
                                {...subLinkStyle.icon}
                                colorTheme="secondary"
                            />

                            <Link {...subLinkStyle.link} colorTheme="secondary">
                                Modules
                            </Link>

                            <Text>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit.
                            </Text>
                        </MenuListItem>
                    </LinkWrapper>

                    <LinkWrapper to="/payment/sdk-js">
                        <MenuListItem colorTheme="secondary">
                            <CardsIcon
                                {...subLinkStyle.icon}
                                colorTheme="secondary"
                            />

                            <Link {...subLinkStyle.link} colorTheme="secondary">
                                SDK JS
                            </Link>

                            <Text>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit.
                            </Text>
                        </MenuListItem>
                    </LinkWrapper>

                    <LinkWrapper to="/payment/sdk-php">
                        <MenuListItem colorTheme="secondary">
                            <CardsIcon
                                {...subLinkStyle.icon}
                                colorTheme="secondary"
                            />

                            <Link {...subLinkStyle.link} colorTheme="secondary">
                                SDK PHP
                            </Link>

                            <Text>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit.
                            </Text>
                        </MenuListItem>
                    </LinkWrapper>
                </MenuList>
            </Menu>

            <Menu>
                <MenuItem isClickable={false}>
                    {t('menu.charityKitLink')}
                </MenuItem>

                <MenuList colorTheme={colorTheme}>
                    <LinkWrapper to="/charityKit/api">
                        <MenuListItem colorTheme="quaternary">
                            <OrganizationIcon
                                {...subLinkStyle.icon}
                                colorTheme="quaternary"
                            />

                            <Link
                                {...subLinkStyle.link}
                                colorTheme="quaternary"
                            >
                                API
                            </Link>

                            <Text>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit.
                            </Text>
                        </MenuListItem>
                    </LinkWrapper>

                    <LinkWrapper to="/charityKit/modules">
                        <MenuListItem colorTheme="quaternary">
                            <OrganizationIcon
                                {...subLinkStyle.icon}
                                colorTheme="quaternary"
                            />

                            <Link
                                {...subLinkStyle.link}
                                colorTheme="quaternary"
                            >
                                Modules
                            </Link>

                            <Text>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit.
                            </Text>
                        </MenuListItem>
                    </LinkWrapper>

                    <LinkWrapper to="/charityKit/sdk-js">
                        <MenuListItem colorTheme="quaternary">
                            <OrganizationIcon
                                {...subLinkStyle.icon}
                                colorTheme="quaternary"
                            />

                            <Link
                                {...subLinkStyle.link}
                                colorTheme="quaternary"
                            >
                                SDK JS
                            </Link>

                            <Text>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit.
                            </Text>
                        </MenuListItem>
                    </LinkWrapper>
                </MenuList>
            </Menu>

            <Menu>
                <MenuItem isClickable={false}>{t('menu.climateKitLink')}</MenuItem>

                <MenuList colorTheme={colorTheme}>
                    <LinkWrapper to="/climateKit/api">
                        <MenuListItem>
                            <LeafIcon {...subLinkStyle.icon} />

                            <Link {...subLinkStyle.link}>API</Link>

                            <Text>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit.
                            </Text>
                        </MenuListItem>
                    </LinkWrapper>

                    <LinkWrapper to="/climateKit/sdk-js">
                        <MenuListItem>
                            <LeafIcon {...subLinkStyle.icon} />

                            <Link {...subLinkStyle.link}>SDK JS</Link>

                            <Text>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit.
                            </Text>
                        </MenuListItem>
                    </LinkWrapper>

                    <LinkWrapper to="/climateKit/widget">
                        <MenuListItem>
                            <LeafIcon {...subLinkStyle.icon} />

                            <Link {...subLinkStyle.link}>CO2 Widget</Link>

                            <Text>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit.
                            </Text>
                        </MenuListItem>
                    </LinkWrapper>
                </MenuList>
            </Menu>
        </PGMenuPrimary>
    );
};

MenuPrimary.propTypes = {
    toggleMenu: PropTypes.func.isRequired,
};

export default MenuPrimary;
