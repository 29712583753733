import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Dot, Link as PGLink } from '@paygreen/paygreen-ui';
import Highlighter from 'react-highlight-words';

const SearchItemBase = styled.li`
    font-size: ${props => props.theme.font.size[props.textSize]};
    line-height: ${props => props.theme.font.lineHeight.md};
    margin-top: ${props => props.theme.space.sm};
    margin-bottom: ${props => props.theme.space.sm};
    margin-left: ${props => props.theme.space.md};
    display: flex;
    align-items: center;
`;

const SearchItem = ({ path, title, query }) => (
    <SearchItemBase>
        <Dot dotSize="xxs" marginRight="md" marginLeft="sm" />

        <Link to={path}>
            <PGLink colorPallet="wab" colorWab="grey50" hasUnderline={false}>
                <Highlighter
                    autoEscape
                    highlightStyle={{ backgroundColor: '#dbdbdb' }}
                    searchWords={query.split(' ')}
                    textToHighlight={title}
                />
            </PGLink>
        </Link>
    </SearchItemBase>
);

export default SearchItem;
