import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { math } from 'polished';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { MDXProvider } from '@mdx-js/react';
import { PGThemeProvider, ThemeDefault } from '@paygreen/paygreen-ui';
import { MdxLink } from 'gatsby-theme-i18n';
import { GlobalStyle } from '../../theme/GlobalStyle';
import bg from '../../../assets/images/bg.png';
import '../../../assets/css/globalStyle.css';
import '../../../assets/css/gatsbyHighlights.css';
import {
    BannerRGPD,
    ContactDivider,
    Header,
    H1Title,
    H2Title,
    H3Title,
    InternalLinkCard,
    InternalLinkCardGrid,
    PText,
    MessageCard,
    Footer,
} from '../../components';

const pageAnimation = keyframes`
    from, 99% {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`;

const Body = styled.div`
    background: url(${bg}) repeat;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    opacity: 0;
    animation-name: ${pageAnimation};
    animation-fill-mode: forwards;
    animation-duration: ${ThemeDefault.transition.sm};
    /* Wait for header style transition (sm) + smoothscroll duration (~0.6s) */
    animation-delay: ${math(ThemeDefault.transition.sm + ' + ' + 0.6)};
`;

const Main = styled.main`
    flex: 1;
    padding-top: 0.1px;
    padding-bottom: ${ThemeDefault.space.lg};
`;

// These components and the 3 markdown components (that map HTML element tag in markdown to detect h1, h2 and p) will be available in all MDX files (thanks to MDXProvider) with no need to import them in each file! :)
// MdxLink is a component to replace the normal anchor tag. This way local links to other files are automatically localized (as it uses `LocalizedLink` i18n theme behind the scenes)
const components = {
    MessageCard,
    a: MdxLink,
    h1: H1Title,
    h2: H2Title,
    h3: H3Title,
    p: PText,
    InternalLinkCard,
    InternalLinkCardGrid,
};

const Layout = ({ hasBanner, children }) => {
    const { t } = useTranslation('pages');

    return (
        <Body>
            <GlobalStyle />

            <PGThemeProvider theme={ThemeDefault}>
                <MDXProvider components={components}>
                    <Header hasTopStyle={hasBanner} />

                    <Main>{children}</Main>

                    <ContactDivider />
                    
                    <Footer />

                    <BannerRGPD
                        theme={ThemeDefault}
                        cookie="user-has-accepted-cookies"
                    >
                        {t('rgpd.text')}

                        <a href="/">
                            <Link>{t('rgpd.link')}</Link>
                        </a>
                    </BannerRGPD>
                </MDXProvider>
            </PGThemeProvider>
        </Body>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    colorTheme: PropTypes.oneOf([
        'primary',
        'secondary',
        'tertiary',
        'quaternary',
        'quinary',
    ]),
    hasbanner: PropTypes.bool,
};

Layout.defaultProps = {
    colorTheme: 'primary',
    hasBanner: true,
};

export default Layout;
